import React from 'react'
import { Nav } from 'shards-react'

import NavSideNavItem from './NavSideNavItem'
import { Store } from '../../flux'

class NavSideNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange)
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    })
  }

  render() {
    const { navItems: items } = this.state
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <NavSideNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    )
  }
}

export default NavSideNavItems

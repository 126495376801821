import * as React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { logout } from '../../utils/auth'
import { Auth } from 'aws-amplify'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from 'shards-react'

export default class NavTopUserActions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    this.toggleUserActions = this.toggleUserActions.bind(this)
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require('./../../images/avatars/reynaldo-lacret.jpg')}
            alt="User Avatar"
          />{' '}
          Reynaldo Lacret{' '}
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem>
            <Link to="app/profile">
              <i className="material-icons">&#xE7FD;</i> Profile
            </Link>
          </DropdownItem>
          {/*
          <DropdownItem to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          */}
          <DropdownItem divider />
          <DropdownItem
            onClick={() =>
              Auth.signOut()
                .then(logout(() => navigate('/login')))
                .catch(err => console.log('eror:', err))
            }
            className="text-danger"
          >
            <i className="material-icons text-danger">&#xE879;</i>
            Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    )
  }
}
// tag={Link}

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'shards-react'

import NavTop from './../NavTop'
import NavSide from './../NavSide'
// import Menu from './../Menu'
import Footer from './../Footer'

const Layout = ({ children, noNavbar, noFooter }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid>
          <Row>
            <NavSide />
            <Col
              className="main-content p-0"
              lg={{ size: 10, offset: 2 }}
              md={{ size: 9, offset: 3 }}
              sm="12"
              tag="main"
            >
              {!noNavbar && <NavTop />}
              {children}
              {!noFooter && <Footer />}
            </Col>
          </Row>
        </Container>
        {/*
        <Menu />
        <div className="content">{children}</div>
        <Footer />
        */}
      </>
    )}
  />
)

Layout.propTypes = {
  noNavbar: PropTypes.bool,
  noFooter: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  noNavbar: false,
  noFooter: false,
}

export default Layout

export default function() {
  return [
    // {
    //   title: 'Dashboard',
    //   to: '/app/dashboard',
    //   htmlBefore: '<i class="material-icons">store</i>',
    //   htmlAfter: '',
    // },
    {
      title: 'Bookings',
      to: '/app/bookings',
      htmlBefore: '<i class="material-icons">local_atm</i>',
    },
    {
      title: 'Ships',
      to: '/app/ships',
      htmlBefore: '<i class="material-icons">directions_boat</i>',
    },
    {
      title: 'Voyages',
      to: '/app/voyages',
      htmlBefore: '<i class="material-icons">map</i>',
    },
    {
      title: 'Destinations',
      htmlBefore: '<i class="material-icons">place</i>',
      to: '/app/destinations',
    },
    {
      title: 'Users',
      htmlBefore: '<i class="material-icons">group</i>',
      to: '/app/users',
    },
    // {
    //   title: 'Agencies',
    //   htmlBefore: '<i class="material-icons">location_city</i>',
    //   to: '#',
    // },
  ]
}

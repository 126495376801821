import React from 'react'
import PropTypes from 'prop-types'
// import { NavLink as RouteNavLink } from 'react-router-dom'
import { NavItem, NavLink } from 'shards-react'
import { Link } from 'gatsby'

const NavSideNavItem = ({ item }) => (
  <div>
    <li className="nav-item">
      <a href={item.to} className="nav-link">
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </a>
    </li>
  </div>
)

NavSideNavItem.propTypes = {
  item: PropTypes.object,
}

export default NavSideNavItem

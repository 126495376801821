import React from 'react'
import { Nav } from 'shards-react'

import NavTopNotifications from './NavTopNotifications'
import NavTopUserActions from './NavTopUserActions'

export default () => (
  <Nav navbar className="border-left flex-row">
    {/*<NavTopNotifications />*/}
    <NavTopUserActions />
  </Nav>
)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Navbar } from 'shards-react'

import NavTopSearch from './NavTopSearch'
import NavTopNav from './NavTopNav'
import NavTopToggle from './NavTopToggle'

const NavTop = ({ layout, stickyTop }) => {
  const classes = classNames(
    'main-navbar',
    'bg-white',
    stickyTop && 'sticky-top'
  )

  return (
    <div className={classes}>
      <Container className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <NavTopSearch />
          <NavTopNav />
          <NavTopToggle />
        </Navbar>
      </Container>
    </div>
  )
}

NavTop.propTypes = {
  layout: PropTypes.string,
  stickyTop: PropTypes.bool,
}

NavTop.defaultProps = {
  stickyTop: true,
}

export default NavTop

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col } from 'shards-react'

import NavSideNavbar from './NavSideNavbar'
import NavSideSearch from './NavSideSearch'
import NavSideNavItems from './NavSideNavItems'

import { Store } from './../../flux'

class NavSide extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems(),
    }

    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange)
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems(),
    })
  }

  render() {
    const classes = classNames(
      'main-sidebar',
      'px-0',
      'col-12',
      this.state.menuVisible && 'open'
    )

    return (
      <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
        <NavSideNavbar hideLogoText={this.props.hideLogoText} />
        <NavSideSearch />
        <NavSideNavItems />
      </Col>
    )
  }
}

NavSide.propTypes = {
  hideLogoText: PropTypes.bool,
}

NavSide.defaultProps = {
  hideLogoText: false,
}

export default NavSide

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Nav, NavItem, NavLink } from 'shards-react'
import { Link } from 'gatsby'

const Footer = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid={contained}>
      <Row>
        <Nav>
          {menuItems.map((item, idx) => (
            <ul className="nav" key={idx}>
              <li className="nav-item">
                <Link className="nav-link" to={item.to}>
                  {item.title}
                </Link>
              </li>
            </ul>
          ))}
        </Nav>
        <span className="copyright ml-auto my-auto mr-2">{copyright}</span>
      </Row>
    </Container>
  </footer>
)

Footer.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
}

// const copyright = () => {
//   return (
//     "{`
//       © {new Date().getFullYear()}
//       {` `}
//       <a href="https://seadream.com">SeaDream Yacht Club</a>
//       {`. `}
//       All rights reserved.
//       `}
//     "
//   )
// }

Footer.defaultProps = {
  contained: false,
  copyright: 'Copyright © 2019 SeaDream Yacht Club',
  menuItems: [
    // {
    //   title: 'Dashboard',
    //   to: '/app/dashboard',
    // },
    {
      title: 'Bookings',
      to: '/app/bookings',
    },
    {
      title: 'Ships',
      to: '/app/ships',
    },
    {
      title: 'Voyages',
      to: '/app/voyages',
    },
    {
      title: 'Users',
      to: '/app/users',
    },
    // {
    //   title: 'Help',
    //   to: '#',
    // },
  ],
}

export default Footer
